import {
  ArrayField,
  ChipField,
  Datagrid,
  Filter,
  SingleFieldList,
  TextField,
  TextInput
} from 'react-admin';
import { CustomList } from '../../custom';

function Filters(props) {
  return (
    <Filter {...props}>
      <TextInput fullWidth label="Buscar por nome" source="name[$regex]" />
    </Filter>
  );
}

export default function Listteams(props) {
  return (
    <CustomList
      {...props}
      filters={<Filters />}
      title={props.options.label}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <Datagrid rowClick="edit">
        <TextField label="Nome" source="name" />
        <ArrayField 
          source="leaderList" 
          label="Líderes"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" size="small" />
          </SingleFieldList>
        </ArrayField>
      </Datagrid>
    </CustomList>
  );
}
