import { Drawer, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useCallback } from 'react';
import {
  Datagrid,
  DateField,
  Filter,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput
} from 'react-admin';
import { Route, useHistory } from 'react-router';

import { CustomList } from '../../custom';
import Show from './show';

function Filters(props) {
  return (
    <Filter {...props}>
      <TextInput fullWidth label="Título" source="title[$regex]" />
      <ReferenceInput
        label="Empresa"
        reference="companies"
        source="recipients[0]"
        filter={{
          $limit: -1
        }}
      >
        <SelectInput />
      </ReferenceInput>
    </Filter>
  );
}

const useStyles = makeStyles(theme => ({
  root: {},
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0
  },
  listWithDrawer: {
    marginRight: 400
  },
  drawerPaper: {
    zIndex: 100
  }
}));

export default function ListNotifications(props) {
  const classes = useStyles();
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push(`/${props.resource}`);
  }, [history, props.resource]);

  return (
    <div className={classes.root}>
      <Route path={`/${props.resource}/:id`}>
        {({ match }) => {
          const isMatch = match?.params?.id && match.params.id !== 'create';
          return (
            <>
              <CustomList
                {...props}
                className={classNames(classes.list, {
                  [classes.listWithDrawer]: isMatch
                })}
                filters={<Filters />}
                title={props.options.label}
                sort={{ field: 'createdAt', order: 'DESC' }}
              >
                <Datagrid rowClick="show">
                  <DateField locales="pt-BR" showTime label="Enviada em" source="createdAt" />
                  <TextField label="Título" source="title" sortable={false} />
                  <ReferenceField
                    label="Empresa"
                    source="recipients[0]"
                    reference="companies"
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </Datagrid>
              </CustomList>

              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper
                }}
              >
                {isMatch && (
                  <Show
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                )}
              </Drawer>
            </>
          );
        }}
      </Route>
    </div>
  );
}
