import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import MuiToolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {
  DateField, DeleteButton, ReferenceField,
  SimpleShowLayout, TextField, useShowController
} from 'react-admin';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 40
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em'
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30
    }
  },
  inlineField: {
    display: 'inline-block',
    width: '50%'
  }
}));

export default function ShowNotifications({ onCancel, ...props }) {
  const classes = useStyles();
  const controllerProps = useShowController(props);
  if (!controllerProps.record) {
    return null;
  }

  const { basePath, record, resource } = controllerProps;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">{record.title}</Typography>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleShowLayout
        {...controllerProps}
        className={classes.form}
      >
        <TextField label="Mensagem" source="body" />

        {record.recipientType === 'COMPANY' && (
          <ReferenceField
            source="recipients[0]"
            reference="companies"
            label="Empresa"
          >
            <TextField source="name" />
          </ReferenceField>
        )}

        <DateField locales="pt-BR" showTime label="Enviada em" source="createdAt" />

        <MuiToolbar className={classes.root}>
          <DeleteButton basePath={basePath} record={record} resource={resource} />
        </MuiToolbar>
      </SimpleShowLayout>
    </div>
  );
}
